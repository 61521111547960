import React from "react";

import Computador from '../../assets/Computador.png'
import Car from '../../assets/car.png'
import LdPage from '../../assets/LdPage.png'
import Page from '../../assets/page.png'
import Seta from './ArrowComponent'

import Insta from '../../assets/Insta.png'
import LinkedIn from '../../assets/LinkedIn.png'
import Email from '../../assets/Email.png'
import Whats from '../../assets/Whats.png'

import {
  Container,
  H1,
  H2,
  P,
  ImageLinks
} from './styles'

function Services() {



  return (
    <Container>

      <header className="link-header">
          <a href="/">Home</a>
          <a href="/services#servicos">Serviços</a>
          <a href="/services#contato">Contato</a>
          <a href="/services#team">Nosso time</a>
      </header>

      <header className="main-header">
        <div className="text">
          <H1>Conheça-nos</H1>

          <div className="line"></div>

          <P>Criada com o objetivo de encurtar os caminhos de quem procura soluções eficientes para as mais diversas necessidades.</P>
          <P>Na tecitura digital, forjamos uma sinfonia de códigos que transcende a funcionalidade, dando vida às visões mais ousadas. Transformando pixels em narrativas cativantes, tecendo a identidade única de sua presença online com maestria e inovação</P>
        </div>
        <div className="div-image"><img className="computer" src={Computador} alt="computador" /></div>

        <div className="seta">
          <Seta />
        </div>
      </header>

      <section id="servicos" className="services">

        <H1>Serviços</H1>
        <div className="products">
          <img className="l-page" src={LdPage} alt="landing-page" />
          <H2>Landing Pages</H2>
        </div>  

        <div className="carAndPage">
          <div  className="products">
            <img className="page" src={Page} alt="pagina-institucionais" />
            <H2>Páginas institucionais</H2>
          </div>

          <div  className="products">
            <img className="car" src={Car} alt="carro-de-mercado" />
            <H2>E-Commerce</H2>
          </div>

        </div>
      </section>

      <section id="contato" className="contact">
        <H1>Contato</H1>

        <div>
          <ImageLinks href="https://www.linkedin.com/company/akila-desenvolvimento-web/" target="__blank"> <img src={LinkedIn} alt="link-images"/> </ImageLinks>
          <ImageLinks href="https://www.instagram.com/akiladesenvolvimentoweb/" target="__blank"> <img src={Insta} alt="link-images"/> </ImageLinks>
          <ImageLinks className="email" href="mailto:akiladesenvolvimentoweb@gmail.com" target="__blank"> <img src={Email} alt="link-images"/> </ImageLinks>
          <ImageLinks href="https://wa.me/5548991775692" target="__blank"> <img src={Whats} alt="link-images"/> </ImageLinks>
        </div>
      </section>

      <section id="team" className="team">
        <H1>Nosso Time</H1>

        <div className="andrei">
          <ImageLinks className="teamlinks" href="https://wa.me/5548991883278" target="__blank"><img src={Whats} alt="whatsapp"/></ImageLinks>

         <H2 className="name1">ANDREI F. VON FRÜHAUF <br/> Desenvolvedor Full Stack</H2>
         
         <ImageLinks className="teamlinks" href="https://www.linkedin.com/in/andrei-von-fruhauf/" target="__blank"><img src={LinkedIn} alt="linkedIn"/></ImageLinks>
        </div>

        <div className="ander">
        <ImageLinks className="teamlinks" href="https://wa.me/5518996775969" target="__blank"><img src={Whats} alt="whatsapp"/></ImageLinks>

         <H2 className="name2">ANDERSON CARLOS GOMES<br /> Web Designer</H2>
         
         <ImageLinks className="teamlinks" href="https://www.linkedin.com/in/anderson-carlos-gomes/" target="__blank"><img src={LinkedIn} alt="linkedIn"/></ImageLinks>
        </div>

      </section>

      <footer>
        <P>2023 Copyright © | Todos os direitos reservados</P>
      </footer>
    </Container>
  )
}

export default Services