import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        text-decoration: none;
        outline: none;
        box-sizing: border-box;
        max-width: 100%;
        font-family: 'Josefin Slab', serif;
    }

`

export default globalStyle