import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './containers/Home'
import Services from './containers/ServicesAndContacts'

const myRoutes = () => {


    return (

        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
            </Routes>
        </Router>

    )
}

export default myRoutes