import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from './style/globalStyle';
import Routes from './Routes'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>
  <Routes />
  <GlobalStyle />
</>
);