import styled from "styled-components";



export const Container = styled.div`

    width: 100vw;
    height: 100vh;
    background: rgba(140, 136, 136, 0.76);
    display: grid;
    grid-template-rows: 0.6fr 2fr 0.1fr;
    header{
        width: 100vw;
        display: flex;
        justify-content: space-around;
        align-items: center;


        .links{
            width: 25vw;
            display: flex;
            justify-content: space-around;

        }
    }

    main{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    footer{
        padding: 2vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    
    @media screen and (max-width: 970px) {
                
        header .links{
            width: 90vw;

        }
    }

    @media screen and (max-width: 220px) {
 


    }

`

export const Logo = styled.img`

    @media screen and (max-width: 430px) {
    
        display: none;

    }

`

export const Links = styled.a`
        
        font-weight: 500;
        color: #040937;
        font-size: 20px;

        @media screen and (max-width: 220px) {
 
            font-size: 15px;

        }

`

export const H1 = styled.h1`
    color: #040937;
    font-size: 240px;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 610px) {
 
        font-size: 150px;

    }

    @media screen and (max-width: 360px) {
        font-size: 80px;
    }

    @media screen and (max-width: 220px) {
 
        font-size: 25vw;

    }


`

export const H2 = styled.h1`
    color: #03FA6E;
    text-align: center;
    font-size: 80px;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 610px) {
 
        font-size: 60px;

    }

    @media screen and (max-width: 430px) {

        font-size: 30px;
        
    }

    @media screen and (max-width: 220px) {
 
        font-size: 12vw;

    }
    
`

export const ImageLinks = styled.a`

    @media screen and (max-width: 220px) {
 
        width: 20vw;

    }

`