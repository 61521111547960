import React from "react";

import InstaLogo from '../../assets/5949033 1Insta.png'
import LinkedInLogo from '../../assets/174857 1linkedin.png'
import LogoAkila from '../../assets/logo 1Logo.png'

import {
  Container,
  Logo,
  Links,
  H1,
  H2,
  ImageLinks
} from './styles'

function App() {



  return (
    <Container>
      <header>
        <Logo src={LogoAkila} alt="Logo" />
        <div className="links">
          <Links href="/">Home</Links>
          <Links href="/services#contato">Contato</Links>
          <Links href="/services">Serviços</Links>
        </div>
      </header>

      <main>
        <H1>Àkila</H1>
        <H2>Desenvolvimento Web</H2>
      </main>

      <footer>
        <ImageLinks href="https://www.instagram.com/akiladesenvolvimentoweb/" target="__blank"><img src={InstaLogo} alt="link-instagram" /></ImageLinks>
        <ImageLinks href="https://www.linkedin.com/company/akila-desenvolvimento-web/" target="__blank"><img src={LinkedInLogo} alt="link-linkedIn" /></ImageLinks>
      </footer>


    </Container>
  )
}

export default App