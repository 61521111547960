import styled from "styled-components";



export const Container = styled.div`
    background: #030729;

    .link-header{
        height: 10vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;

        a{
            color: #03FA6E;
        }
    }

    .main-header{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 15vh;

        .text{
            width: 40vw;
            display: flex;
            flex-direction: column;
            gap: 5vh;
        }

        .line{
            border: 1px solid #03FA6E;
        }

        .seta{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .services{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8vh;
        margin-bottom: 15vh;

        
        .products{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2vh;
        }
        
        .carAndPage{
            display: flex;
            width: 100vw;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            gap: 1vh;
        }

    }

    .contact{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15vh;

        div{
            display: flex;
            gap: 6vw;
        }
    }

    .team{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15vh;
        gap: 15vh;

        .andrei{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 2vh;
            margin-bottom: 2vh;

            .name1{
                line-height: 2;
            }
        }

        .ander{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 6.5vh;

            .name2{
                line-height: 2;
            }

        }

        .name1{
            width: 50vw;
        }

        .name2{
            width: 45vw;
        }
    }

    footer{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20vh;
        background: rgba(140, 136, 136, 0.76);

        P{
            color: #030729;
        }
    }


    @media screen and (max-width: 1400px){
        
        .main-header .computer{
            width: 600px;
        }

    }

    @media screen and (max-width: 1100px){
        
        .main-header .computer{
            width: 500px;
        }

        .main-header {
            flex-direction: column-reverse;
            gap: 10vh;
        }

        .main-header .text{
            width: 80vw;

        }
    }


    @media screen and (max-width: 1200px){
        
        .services .l-page{
            width: 15vw;
        }

        .services .page{
            width: 12vw;
        }

        .services .car{
            width: 22vw;
        }

        .team .andrei .name1 {
            width: 70vw;
        }

        .team .andrei .teamlinks{
            width: 8vw;
        }

        .team .ander .teamlinks{
            width: 8vw;
        }

    }

    @media screen and (max-width: 600px){

        .contact .email{
            width: 17.5vw;
        }


    }


    @media screen and (max-width: 450px){
        
        .link-header{
            font-size: 5vw;
        }

    }
`

export const H1 = styled.h1`
    color: #03FA6E;
    font-family: Josefin Slab;
    font-size: 80px;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 1200px){
        
        font-size: 10vw;

    }

`

export const H2 = styled.h1`
    color: #03FA6E;
    font-family: Josefin Slab;
    font-size: 50px;
    font-weight: 400;
    line-height: normal;


    @media screen and (max-width: 1200px){
        
        font-size: 5vw;

    }

`

export const P = styled.p`
    color: #03FA6E;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 650px){
        font-size: 4vw;
    }
`

export const ImageLinks = styled.a`

    @media screen and (max-width: 600px){

        width: 15vw;

    }

`