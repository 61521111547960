// ArrowComponent.js
import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const moveUpDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1vh); // Alterado para movimento para baixo
  }
`;

const ArrowContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  animation: ${({ isVisible }) =>
    isVisible ? css`${fadeIn} 2s ease-out forwards, ${moveUpDown} 1.3s ease-in-out infinite` : "none"};
  content: " ";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #03FA6E; // Alterado para uma seta virada para baixo

  @media screen and (max-width: 450px) {
    position: fixed;
    bottom: 260px;
    left: 50%;
  }
`;

const Arrow = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const threshold = 200;
      setIsVisible(scrolled < threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <ArrowContainer isVisible={isVisible} />;
};

export default Arrow;
